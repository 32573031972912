import React, { Fragment, useEffect, useRef, useState } from 'react';
import aayush from '../../../assets/games/aayush.jpg';
import progress from '../../../assets/games/progress.gif';
import progressPause from '../../../assets/games/progress-pause.png';
import ball from '../../../assets/games/rotating-ball.gif';
import './index.css';
import useInterval from '../../../helper/use-interval';

function AayushDino() {
  const [playing, setPlaying] = useState(false);
  const [jump, setJump] = useState(false);
  const [score, setScore] = useState(0);

  const playRef = useRef(false);
  const ballRef = useRef();
  const scoreRef = useRef(0);

  const jumpFunction = () => {
    setJump(true);
    setTimeout(() => {
      setJump(false);
    }, 600);
  };

  useInterval(
    () => {
      const ball = document.getElementById('ball');
      let rect = ball.getBoundingClientRect();
      const aayush = document.getElementById('aayush');
      let aayushRect = aayush.getBoundingClientRect();
      if (
        rect.left < aayushRect.right &&
        rect.right > aayushRect.left &&
        rect.top < aayushRect.bottom &&
        rect.bottom > aayushRect.top &&
        !jump
      ) {
        alert('Game Over! Your Score is : ' + scoreRef.current);
        setPlaying(false);
        setScore(0);
        scoreRef.current = 0;
        playRef.current = false;
      }
    },
    playing ? 5 : null
  );

  useEffect(() => {
    const spaceFunction = (e) => {
      if (e.key === ' ') {
        if (playRef.current) {
          jumpFunction();
        } else {
          setPlaying(true);
          playRef.current = true;
        }
      }
    };
    document.addEventListener('keydown', spaceFunction);
    return () => document.removeEventListener('keydown', spaceFunction);
  }, []);

  useInterval(
    () => {
      setScore((prev) => prev + 1);
      scoreRef.current = scoreRef.current + 1;
    },
    playing ? 1000 : null
  );

  return (
    <Fragment>
      <div
        className="h-screen lg:h-full"
        onClick={() => {
          if (playRef.current) {
            jumpFunction();
          } else {
            setPlaying(true);
            playRef.current = true;
          }
        }}
      >
        <div className="w-screen text-center mt-10">
          <p className="font-bold hidden lg:block">
            Press space key to {playing ? 'jump' : 'start game'}
          </p>
          <p className="font-bold block lg:hidden">
            Press anywhere to {playing ? 'jump' : 'start game'}
          </p>
          {playing && <p>Score : {score}</p>}
        </div>
        <div className="w-screen flex items-center mt-60 flex-col relative transition-all">
          <div className={`z-50 ${jump && 'lg:-mt-60 -mt-40 jump'}`}>
            <img
              alt="nothing"
              src={aayush}
              className="lg:w-10 w-5 rounded-full lg:mt-0 mt-10"
              id="aayush"
            />
          </div>
          {playing && (
            <img
              alt="nothing"
              src={ball}
              className="lg:h-20 h-10 z-50 absolute lg:top-5 top-12 ball"
              ref={ballRef}
              id="ball"
            />
          )}
          <div className={`absolute ${playing ? 'top-12' : 'top-20'}`}>
            {playing ? (
              <div className="w-screen h-10">
                <img
                  alt="nothing"
                  src={progress}
                  className="h-20 w-screen rotate-180"
                />
              </div>
            ) : (
              <div className="w-screen sm:h-10 px-10">
                <img
                  alt="nothing"
                  src={progressPause}
                  className="h-2 w-screen rotate-180 rounded-xl"
                />
              </div>
            )}
          </div>
          <button
            disabled={!playing}
            className={`absolute text-white p-2 rounded-full font-semibold top-28 ${
              !playing ? 'bg-gray-500' : 'bg-black'
            }`}
            onClick={jumpFunction}
          >
            JUMP
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default AayushDino;
