import React from "react";

const Box = ({
  left,
  right,
  top,
  bottom,
  value,
  onClick,
  background,
  text,
}) => {
  return (
    <div
      className={`flex justify-center items-center w-24 h-24 cursor-pointer border-black ${
        left && "border-l-8"
      } ${right && "border-r-8"} ${bottom && "border-b-8"} ${
        top && "border-t-8"
      }`}
      style={{
        backgroundColor: background,
      }}
      onClick={onClick}
    >
      <p
        className="text-5xl font-semibold"
        style={{
          color: text ? text : "black",
        }}
      >
        {value}
      </p>
    </div>
  );
};

export default Box;
