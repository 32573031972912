import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routingConstants } from '../../../constants';

const TictacToeHome = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center mt-5">
      <div className="mb-4 flex flex-col">
        <p className="text-5xl text-black italic font-extrabold">Tic Tac Toe</p>
        <p className="text-black text-base font-semibold self-end">
          -by Aayush
        </p>
      </div>
      <div className="font-semibold text-black text-xl">Select Mode</div>
      <div className="w-screen flex flex-col items-center">
        <div
          className="rounded-xl p-3 mt-5 bg-black w-2/4 flex justify-center hover:bg-gray-300 text-white text-xl hover:font-semibold hover:text-black cursor-pointer"
          onClick={() =>
            navigate(routingConstants.TIC_TAC_TOE_GAME, {
              state: { letter: 'X' },
            })
          }
        >
          <p className="">Single Player</p>
        </div>
        <div
          className="rounded-xl p-3 mt-5 bg-black w-2/4 flex justify-center hover:bg-gray-300 text-white text-xl hover:font-semibold hover:text-black cursor-pointer"
          onClick={() =>
            navigate(routingConstants.TIC_TAC_TOE_GAME, {
              state: { two: true, letter: 'X' },
            })
          }
        >
          <p>Two Player</p>
        </div>
      </div>
    </div>
  );
};

export default TictacToeHome;
