import tictactoe from "../../assets/games/tictactoe.png";
import aayushdino from "../../assets/games/aayushdino.jpg";
import { routingConstants } from "../../constants";

export const allGames = [
  {
    name: "Tic Tac Toe",
    path: routingConstants.TIC_TAC_TOE_NO_STAR,
    icon: tictactoe,
  },
  { name: "Aayush Dino", path: routingConstants.AAYUSH_DINO, icon: aayushdino },
];
