import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { routingConstants } from './constants';
import { Games, Wait } from './pages';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path={routingConstants.HOME} element={<Wait />} />
            <Route path={routingConstants.GAMES} element={<Games />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
