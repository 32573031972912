import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headerName: 'GAMES',
};

export const basicSlice = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    changeHeaderName: (state, action) => {
      state.headerName = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeHeaderName } = basicSlice.actions;

export default basicSlice.reducer;
