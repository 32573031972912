import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { allGames } from './allgames';
import * as basicActions from '../../redux/basic/basicSlice';
import { connect } from 'react-redux';

function GamesList({ changeHeaderName }) {
  useEffect(() => {
    changeHeaderName && changeHeaderName('GAMES');
  }, [changeHeaderName]);

  return (
    <div className="lg:p-2 md:p-2 p-2 px-6 flex lg:ml-10 flex-wrap lg:justify-start md:justify-start justify-between select-none">
      {allGames?.map((game) => {
        return (
          <Link
            to={game?.path}
            key={Math.random()}
            onClick={() => changeHeaderName && changeHeaderName(game?.name)}
          >
            <div className="w-44 lg:w-56 h-52 lg:h-64 lg:ml-5">
              <div className="lg:w-52 lg:h-60 w-44 h-52 bg-gradient-to-r from-rose-900 to-rose-500 mt-5 rounded-xl hover:scale-110 transition-all flex items-center flex-col">
                <div className="h-2/3 w-full flex items-center justify-center">
                  <img
                    src={game?.icon}
                    className="lg:w-20 lg:h-20 w-14 h-14 rounded-full"
                    alt="game"
                  />
                </div>
                <div className="h-1/3 w-full flex items-center justify-center bg-black text-white rounded-xl">
                  <p className="font-semibold">{game?.name}</p>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, basicActions), dispatch);
}

export default connect(null, mapDispatchToProps)(GamesList);
