import React, { Fragment } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { routingConstants } from '../../constants';
import { TictacToeHome, GamesList, AayushDino, Game } from '../../pages';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { connect } from 'react-redux';

function Games({ headerName }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="w-screen p-5 bg-gradient-to-r from-black to-orange-900 flex items-center lg:justify-center justify-end relative">
        <ArrowBackIcon
          className="text-white absolute left-5 cursor-pointer hover:scale-110 transition-all"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-white font-semibold text-xl italic select-none">
          {headerName}
        </p>
      </div>
      <Routes>
        <Route index element={<GamesList />} />
        <Route
          path={routingConstants.TIC_TAC_TOE}
          element={
            <Routes>
              <Route index element={<TictacToeHome />} />
              <Route
                path={routingConstants.TIC_TAC_TOE_GAME}
                element={<Game />}
              />
            </Routes>
          }
        />
        <Route path={routingConstants.AAYUSH_DINO} element={<AayushDino />} />
      </Routes>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { basic } = state;
  return { headerName: basic.headerName };
}

export default connect(mapStateToProps)(Games);
