import calm from "../assets/images/keepcalm.png";
import "../App.css";
import { Link } from "react-router-dom";
import { routingConstants } from "../constants";

function Wait() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={calm} className="App-logo" alt="logo" />
        <p className="text-lg mt-2">Work in progress.</p>
        <Link to={routingConstants.GAMES_NO_STAR}>
          <p className="text-xl mt-4 italic hover:font-semibold hover:underline">
            Play games till then
          </p>
        </Link>
      </header>
    </div>
  );
}

export default Wait;
