import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routingConstants } from '../../../constants';
import Box from './box';

const Game = () => {
  const params = useLocation();
  const navigate = useNavigate();

  const [turn, setTurn] = useState(true);
  const [selected] = useState(params?.state?.letter);
  const [twoPlayer] = useState(params?.state?.two);
  const [boxValue, setBoxValue] = useState({
    boxValue0: '',
    boxValue1: '',
    boxValue2: '',
    boxValue3: '',
    boxValue4: '',
    boxValue5: '',
    boxValue6: '',
    boxValue7: '',
    boxValue8: '',
  });
  const [winArrayX, setWinArrayX] = useState([]);
  const [winArrayO, setWinArrayO] = useState([]);
  const [textColor, setTextColor] = useState({
    textColor0: 'black',
    textColor1: 'black',
    textColor2: 'black',
    textColor3: 'black',
    textColor4: 'black',
    textColor5: 'black',
    textColor6: 'black',
    textColor7: 'black',
    textColor8: 'black',
  });
  const [bgColor, setBgColor] = useState({
    bgColor0: 'white',
    bgColor1: 'white',
    bgColor2: 'white',
    bgColor3: 'white',
    bgColor4: 'white',
    bgColor5: 'white',
    bgColor6: 'white',
    bgColor7: 'white',
    bgColor8: 'white',
  });
  const [winX, setWinX] = useState(false);
  const [winO, setWinO] = useState(false);

  const [refreshState, setRefreshState] = useState(false);
  const [you, setYou] = useState(0);
  const [computer, setComputer] = useState(0);

  let winChances = [
    [0, 1, 2],
    [0, 3, 6],
    [0, 4, 8],
    [1, 4, 7],
    [2, 5, 8],
    [2, 4, 6],
    [3, 4, 5],
    [6, 7, 8],
  ];

  const automateFunction = () => {
    let random;
    winChances.forEach((item) => {
      if (random) {
        return;
      }
      if (winArrayX.includes(item[0]) && winArrayX.includes(item[1])) {
        if (boxValue['boxValue' + item[2]] === '') {
          random = item[2];
        }
      } else if (winArrayO.includes(item[0]) && winArrayO.includes(item[1])) {
        if (boxValue['boxValue' + item[2]] === '') {
          random = item[2];
        }
      } else if (winArrayO.includes(item[1]) && winArrayO.includes(item[2])) {
        if (boxValue['boxValue' + item[0]] === '') {
          random = item[0];
        }
      } else if (winArrayO.includes(item[0]) && winArrayO.includes(item[2])) {
        if (boxValue['boxValue' + item[1]] === '') {
          random = item[1];
        }
      } else if (winArrayX.includes(item[1]) && winArrayX.includes(item[2])) {
        if (boxValue['boxValue' + item[0]] === '') {
          random = item[0];
        }
      } else if (winArrayX.includes(item[0]) && winArrayX.includes(item[2])) {
        if (boxValue['boxValue' + item[1]] === '') {
          random = item[1];
        }
      }
    });
    if (!random) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      random = arr[Math.floor(Math.random() * arr.length)];
    }
    let dynamicState = 'boxValue' + random;
    if (boxValue[dynamicState] === '') {
      setBoxValue({
        ...boxValue,
        [dynamicState]: selected === 'X' ? 'O' : 'X',
      });
      setWinArrayO([...winArrayO, random]);
      setTurn(true);
    } else {
      if (
        boxValue.boxValue0 === '' ||
        boxValue.boxValue1 === '' ||
        boxValue.boxValue2 === '' ||
        boxValue.boxValue3 === '' ||
        boxValue.boxValue4 === '' ||
        boxValue.boxValue5 === '' ||
        boxValue.boxValue6 === '' ||
        boxValue.boxValue7 === '' ||
        boxValue.boxValue8 === ''
      )
        automateFunction();
    }
  };

  useEffect(() => {
    if (!winX && !winO) {
      let finished;
      winChances.forEach((item, index) => {
        let text0 = 'textColor' + item[0];
        let text1 = 'textColor' + item[1];
        let text2 = 'textColor' + item[2];
        let bg0 = 'bgColor' + item[0];
        let bg1 = 'bgColor' + item[1];
        let bg2 = 'bgColor' + item[2];
        if (
          winArrayX.includes(item[0]) &&
          winArrayX.includes(item[1]) &&
          winArrayX.includes(item[2])
        ) {
          setBgColor({ ...bgColor, [bg0]: 'red', [bg1]: 'red', [bg2]: 'red' });
          setTextColor({
            ...textColor,
            [text0]: 'yellow',
            [text1]: 'yellow',
            [text2]: 'yellow',
          });
          setWinX(true);
          setYou((prev) => prev + 1);
          finished = true;
        } else if (
          winArrayO.includes(item[0]) &&
          winArrayO.includes(item[1]) &&
          winArrayO.includes(item[2])
        ) {
          setBgColor({ ...bgColor, [bg0]: 'red', [bg1]: 'red', [bg2]: 'red' });
          setTextColor({
            ...textColor,
            [text0]: 'yellow',
            [text1]: 'yellow',
            [text2]: 'yellow',
          });
          setWinO(true);
          setComputer((prev) => prev + 1);
          finished = true;
        }
        if (index === winChances.length - 1 && !finished) {
          setRefreshState((prev) => !prev);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turn, winArrayX, winArrayO]);

  useEffect(() => {
    if (!turn && !twoPlayer) {
      automateFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshState]);

  const chanceFunction = (state, number) => {
    if (boxValue[state] === '' && !winX && !winO) {
      if (turn) {
        setBoxValue({ ...boxValue, [state]: selected });
        setWinArrayX([...winArrayX, number]);
      } else if (twoPlayer) {
        setBoxValue({ ...boxValue, [state]: selected === 'X' ? 'O' : 'X' });
        setWinArrayO([...winArrayO, number]);
      }
      setTurn((prev) => !prev);
    }
  };

  const resetFunction = (score, letter) => {
    setTurn(true);
    setWinArrayX([]);
    setWinArrayO([]);
    setWinO(false);
    setWinX(false);
    setBgColor({
      bgColor0: 'white',
      bgColor1: 'white',
      bgColor2: 'white',
      bgColor3: 'white',
      bgColor4: 'white',
      bgColor5: 'white',
      bgColor6: 'white',
      bgColor7: 'white',
      bgColor8: 'white',
    });
    setTextColor({
      textColor0: 'black',
      textColor1: 'black',
      textColor2: 'black',
      textColor3: 'black',
      textColor4: 'black',
      textColor5: 'black',
      textColor6: 'black',
      textColor7: 'black',
      textColor8: 'black',
    });
    setBoxValue({
      boxValue0: '',
      boxValue1: '',
      boxValue2: '',
      boxValue3: '',
      boxValue4: '',
      boxValue5: '',
      boxValue6: '',
      boxValue7: '',
      boxValue8: '',
    });
    if (score) {
      setYou(0);
      setComputer(0);
    }
    if (letter) {
      setYou(0);
      setComputer(0);
      navigate(
        `/${
          routingConstants.GAMES_NO_STAR + routingConstants.TIC_TAC_TOE_NO_STAR
        }`
      );
    }
  };

  return (
    <div className="flex flex-col w-screen items-center justify-center">
      <div className="mb-5 w-full flex flex-col items-center">
        <p className="text-3xl font-semibold">Score</p>
        <div className="flex w-full px-2 w-7/8 justify-between">
          <p className="text-xl font-semibold">
            {twoPlayer ? 'First Player' : 'You'} : {you}
            {/* {twoPlayer
              ? route.params.firstPlayer.trim() !== ''
                ? route.params.firstPlayer
                : 'First Player'
              : route.params.firstPlayer.trim() !== ''
              ? route.params.firstPlayer
              : 'You'}{' '}
            : {you} */}
          </p>
          <p className="text-xl font-semibold">
            {computer} : {twoPlayer ? 'Second Player' : 'Computer'}
            {/* {twoPlayer
              ? route.params.secondPlayer.trim() !== ''
                ? route.params.secondPlayer
                : 'Second Player'
              : 'Computer'}{' '}
            : {computer} */}
          </p>
        </div>
      </div>
      <div className="flex">
        <Box
          right
          bottom
          text={textColor.textColor0}
          background={bgColor.bgColor0}
          value={boxValue.boxValue0}
          onClick={() => chanceFunction('boxValue0', 0)}
        />
        <Box
          right
          bottom
          text={textColor.textColor1}
          background={bgColor.bgColor1}
          value={boxValue.boxValue1}
          onClick={() => chanceFunction('boxValue1', 1)}
        />
        <Box
          bottom
          value={boxValue.boxValue2}
          text={textColor.textColor2}
          background={bgColor.bgColor2}
          onClick={() => chanceFunction('boxValue2', 2)}
        />
      </div>
      <div className="flex">
        <Box
          right
          bottom
          value={boxValue.boxValue3}
          text={textColor.textColor3}
          background={bgColor.bgColor3}
          onClick={() => chanceFunction('boxValue3', 3)}
        />
        <Box
          right
          bottom
          value={boxValue.boxValue4}
          text={textColor.textColor4}
          background={bgColor.bgColor4}
          onClick={() => chanceFunction('boxValue4', 4)}
        />
        <Box
          bottom
          value={boxValue.boxValue5}
          text={textColor.textColor5}
          background={bgColor.bgColor5}
          onClick={() => chanceFunction('boxValue5', 5)}
        />
      </div>
      <div className="flex">
        <Box
          right
          value={boxValue.boxValue6}
          text={textColor.textColor6}
          background={bgColor.bgColor6}
          onClick={() => chanceFunction('boxValue6', 6)}
        />
        <Box
          right
          value={boxValue.boxValue7}
          text={textColor.textColor7}
          background={bgColor.bgColor7}
          onClick={() => chanceFunction('boxValue7', 7)}
        />
        <Box
          value={boxValue.boxValue8}
          text={textColor.textColor8}
          background={bgColor.bgColor8}
          onClick={() => chanceFunction('boxValue8', 8)}
        />
      </div>
      <div className="w-2/4">
        <div
          className="text-center p-3 mt-3 bg-black rounded-xl cursor-pointer"
          onClick={() => resetFunction()}
        >
          <p className="text-xl text-white">Reset</p>
        </div>
        <div
          className="text-center p-3 mt-3 bg-black rounded-xl cursor-pointer"
          onClick={() => resetFunction(true)}
        >
          <p className="text-xl text-white">Reset Score</p>
        </div>
        <div
          className="text-center p-3 mt-3 bg-black rounded-xl cursor-pointer"
          onClick={() => resetFunction(true, true)}
        >
          <p className="text-xl text-white">Reset Full Game</p>
        </div>
      </div>
    </div>
  );
};

export default Game;
