export const routingConstants = {
  HOME: '/',
  WAIT_PAGE: 'wait-page',
  GAMES: 'games/*',
  GAMES_NO_STAR: 'games',
  GAMES_LIST: 'games-list',
  TIC_TAC_TOE: 'tic-tac-toe/*',
  TIC_TAC_TOE_NO_STAR: 'tic-tac-toe',
  TIC_TAC_TOE_GAME: 'game',
  AAYUSH_DINO: 'aayush-dino',
};
